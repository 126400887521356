<template>
  <moe-page title="用户管理">
    <!-- 筛选信息 -->
    <moe-inquire @search="userSearch">
      <el-form-item label="用户编号">
        <el-input :value="userParams.id" @input="(value) => userParams.id = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入用户编号" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="昵称">
        <el-input v-model.trim="userParams.name" placeholder="输入昵称" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model.trim="userParams.phone" placeholder="输入手机号" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="宠物数量">
        <el-select v-model="userParams.petsNum" placeholder="选择宠物数量" filterable clearable>
          <el-option label="1" :value="1" />
          <el-option label="2" :value="2" />
          <el-option label="3只及以上" :value="3" />
        </el-select>
      </el-form-item>
      <el-form-item label="选择日期">
        <el-date-picker v-model="date" type="daterange" value-format="yyyy-MM-dd 00:00:00" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="(e) => ([userParams.minTime, userParams.maxTime] = e || ['', ''])" />
      </el-form-item>
      <el-form-item label="用户标签">
        <el-select v-model="userParams.tagId" placeholder="选择用户标签" filterable clearable>
          <el-option :label="item.label" :value="item.value" v-for="(item, index) in userTagList" :key="index" />
        </el-select>
      </el-form-item>
    </moe-inquire>

    <!-- 用户信息 -->
    <moe-table ref="userTable" url="/appUser/pageList" :params="userParams" :numberShow="false">
      <el-table-column prop="id" label="用户编号" min-width="80" />
      <el-table-column prop="name" label="昵称" min-width="130" />
      <el-table-column prop="phone" label="手机号" min-width="140">
        <template slot-scope="{ row }">
          {{ `+${row.regionCode}-${row.phone}` }}
        </template>
      </el-table-column>
      <el-table-column prop="sex" label="性别" min-width="130">
        <template slot-scope="{ row }">{{ $moe_format.getSex(row.sex) }}</template>
      </el-table-column>
      <el-table-column prop="petsNum" label="宠物数量" width="90" />
      <el-table-column prop="deviceNum" label="关联设备数" min-width="150" />
      <el-table-column prop="createTime" label="创建时间" min-width="150">
        <template slot-scope="{ row }">{{ $moe_time.getTimeStamp(row.createTime) }}</template>
      </el-table-column>
      <el-table-column label="操作" width="210" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="warning" size="mini" icon="el-icon-refresh" @click="resetPassword(row.id)">重置密码</el-button>
          <el-button type="success" size="mini" icon="el-icon-view" @click="$router.push(`/user/detail?id=${row.id}`)">详情</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'UserList',
  data() {
    //用户筛选信息
    let userParams = {
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
      id: '',
      name: '', //昵称
      phone: '', //手机号
      petsNum: '', //宠物数量
      minTime: '', //开始日期
      maxTime: '', //结束日期
      tagId: '',  //用户标签id
    };

    return {
      userParams, //用户筛选信息
      date: [], //日期
      userTagList: [],
    };
  },
  methods: {
    /**
     * 用户搜索
     **/
    userSearch(isSearch) {
      if (!isSearch) {
        this.userParams = {
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
          id: '',
          name: '', //昵称
          phone: '', //手机号
          petsNum: '', //宠物数量
          minTime: '', //开始日期
          maxTime: '', //结束日期
          tagId: '', //用户标签id
        };
        this.date = []; //清空日期
      }

      //刷新用户表格
      this.$refs.userTable.searchData();
    },

    /**
     * 重置密码
     **/
    resetPassword(userId) {
      this.$moe_layer.confirm('是否要重置用户密码 ?', () => {
        // 调用删除账号API
        this.$moe_api.USER_API.resetPassword({ userId }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('重置成功，密码为：123456');

            //刷新账号表格
            this.$refs.userTable.loadingData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },

    /** 获取所有用户标签 */
    async req_getUserTagListAll() {
      const { code, result, message } = await this.$moe_api.USER_API.getUserTagListAll();
      if (code === 200) {
        this.userTagList = result.list.map(({ name, id }) => {
          return {
            label: name,
            value: id
          }
        })
      } else {
        this.$moe_msg.error(message);
      }
    },
  },
  mounted() {
    this.req_getUserTagListAll();
  }
};
</script>
